export default {
  mainsightsfaviconlogo1024: require('../assets/images/MAInsightsFaviconLogo1024.png'),
  nccbwem0cxji6jiufhxlz: require('../assets/images/NccBweM0CxJi6JIufHXLz.png'),
  mainsightslogowithnameright: require('../assets/images/MAInsightsLogoWithNameRight.png'),
  mainsightsfaviconlogo1024new: require('../assets/images/MAInsightsFaviconLogo1024New.png'),
  mainsightsfaviconlogo1024cropped: require('../assets/images/MAInsightsFaviconLogo1024Cropped.png'),
  NKPIcon1024: require('../assets/images/NKPIcon1024.png'),
  LogoMobileApp: require('../assets/images/LogoMobileApp.png'),
  mainsightslogowithnameleftsignature2: require('../assets/images/MAInsightsLogoWithNameLeft(signature)(2).png'),
  logorightsmall: require('../assets/images/LogoRightSmall.png'),
  logorightsmall2: require('../assets/images/LogoRightSmall2.png'),
  mainsightsmigrationinformationvisual: require('../assets/images/MAInsightsMigrationInformationVisual.png'),
  dummyqrcodeforappstoreapp: require('../assets/images/DummyQRCodeForAppStoreApp.png'),
  mainsightslogonew: require('../assets/images/MAInsightsLogoNew.png'),
};
